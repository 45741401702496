import { LINKS } from '@utils/constants';
import {
	IProduct,
	ISoftwareUpdate,
	ISoftwareUpdateFrontmatter,
} from '@utils/types';
import { graphql } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

export default function SoftwareUpdateTemplate({
	data,
	pageContext: {
		breadcrumb: { crumbs },
	},
}: {
	data: {
		markdownRemark: {
			fields: { slug: string };
			frontmatter: ISoftwareUpdate;
			html: string;
		};
		releases: {
			edges: { node: ISoftwareUpdateFrontmatter }[];
		};
		products: {
			edges: { node: IProduct }[];
		};
		banner: { childImageSharp: { gatsbyImageData: IGatsbyImageData } };
	};
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
}) {
	const { t } = useTranslation();

	const { markdownRemark, banner, releases, products } = data;
	if (!markdownRemark) return null;

	const { frontmatter, html, fields } = markdownRemark;


	return (
		<Page>
			<SEO
				title={`${frontmatter.title} | ${t("_SoftwareReleases")} | Realis Simulation`}
				description=""
			/>

			<Banner
				title={frontmatter.title}
				image={{
					src: banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection layout="wide">
				<div
					className="col xl7 m12 m-spacer page__content page__content--dual"
					dangerouslySetInnerHTML={{ __html: html }}
				/>
				<Aside>
					<Aside.Widget
						title={t("_PreviousVersions")}
						className="widget__sibling__pages">
						<AssetList>
							{releases.edges.map(
								(
									{
										node,
									}: { node: ISoftwareUpdateFrontmatter },
									i: number
								) => (
									<Link
										key={i}
										to={`${LINKS.SUPPORT_SOFTWARE_UPDATES}${node.fields.slug}`}
										className="assets__link"
										activeClassName="active">
										{node.frontmatter.title}
									</Link>
								)
							)}
						</AssetList>
					</Aside.Widget>
					<Aside.Widget
						title={t("_AllProducts")}
						className="widget__path__pages">
						<AssetList>
							{products.edges.map(
								({ node }: { node: IProduct }, i: number) => (
									<Link
										key={i}
										to={node.link}
										className="assets__link"
										activeClassName="active">
										{t(node.titlekey) /* i18next-extract-disable-line */}
									</Link>
								)
							)}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
}

export const pageQuery = graphql`
	query ($language: String!, $slug: String!) {		
		locales: allLocale(
			filter: { ns: { in: ["_common"] }, language: { eq: $language } }
		) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		markdownRemark(
      		fields: { language: { eq: $language }, slug: { eq: $slug } }
		) {
			frontmatter {
				title
				summary
			}
			html
		}
		releases: allMarkdownRemark(
			filter: { 
				fileAbsolutePath: { regex: "/software-updates/" } 
				fields: { language: { eq: $language } }
			}
			sort: { fields: frontmatter___date, order: DESC }
		) {
			edges {
				node {
					fields {
						slug
					}
					frontmatter {
						title
						summary
					}
				}
			}
		}

		products: allProductsJson {
			edges {
				node {
					titlekey
					link
				}
			}
		}

		banner: file(relativePath: { eq: "content/banner-hand.jpg" }) {
			...imageBreaker
		}
	}
`;
